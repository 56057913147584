/* line 1, ../sass/_section.scss */
.section {
  height: auto;
  padding: 100px 0px;
}

@media only screen and (max-width: 900px) {
  /* line 6, ../sass/_section.scss */
  #home-image-div {
    min-height: 800px;
  }
}

/* line 1, ../sass/_navbar.scss */
.navbar {
  width: 100%;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 1px 0px 0px #c6c6c6;
  z-index: 10;
  position: sticky;
  top: 0;
  backdrop-filter: saturate(180%) blur(20px);
  transition: all 0.5s;
}
/* line 11, ../sass/_navbar.scss */
.navbar.active {
  height: 100vh;
  background-color: black;
}
/* line 14, ../sass/_navbar.scss */
.navbar.active .nav-inner .nav-links .nav-item .nav-link {
  color: #fff;
}
/* line 17, ../sass/_navbar.scss */
.navbar.active .nav-inner .nav-links .nav-item .nav-logo {
  filter: invert(100%);
}
/* line 21, ../sass/_navbar.scss */
.navbar .nav-inner {
  max-width: 980px;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  position: relative;
}
/* line 27, ../sass/_navbar.scss */
.navbar .nav-inner .nav-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  height: 18px;
  width: 24px;
  display: none;
}
@media only screen and (max-width: 700px) {
  /* line 27, ../sass/_navbar.scss */
  .navbar .nav-inner .nav-icon {
    display: block;
  }
}
/* line 37, ../sass/_navbar.scss */
.navbar .nav-inner .nav-icon:hover {
  cursor: pointer;
}
/* line 40, ../sass/_navbar.scss */
.navbar .nav-inner .nav-icon .nav-line {
  width: 100%;
  height: 1px;
  background-color: #000;
  position: absolute;
  left: 0;
  transition: all 0.25s;
}
/* line 47, ../sass/_navbar.scss */
.navbar .nav-inner .nav-icon .nav-line.top {
  top: 4px;
}
/* line 50, ../sass/_navbar.scss */
.navbar .nav-inner .nav-icon .nav-line.bot {
  bottom: 4px;
}
/* line 55, ../sass/_navbar.scss */
.navbar .nav-inner .nav-icon.active .nav-line {
  background-color: #fff;
}
/* line 58, ../sass/_navbar.scss */
.navbar .nav-inner .nav-icon.active .top {
  top: 8px;
  transform: rotate(45deg);
}
/* line 62, ../sass/_navbar.scss */
.navbar .nav-inner .nav-icon.active .bot {
  top: 8px;
  transform: rotate(-45deg);
}
/* line 68, ../sass/_navbar.scss */
.navbar .nav-inner .nav-links {
  padding: 0px;
  margin: 0px;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  list-style: none;
  position: relative;
}
@media only screen and (max-width: 700px) {
  /* line 68, ../sass/_navbar.scss */
  .navbar .nav-inner .nav-links {
    display: block;
  }
}
/* line 80, ../sass/_navbar.scss */
.navbar .nav-inner .nav-links .nav-item {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  overflow: hidden;
}
@media only screen and (max-width: 700px) {
  /* line 86, ../sass/_navbar.scss */
  .navbar .nav-inner .nav-links .nav-item:nth-child(2) {
    border-top: 1px solid white;
  }
}
@media only screen and (max-width: 700px) {
  /* line 80, ../sass/_navbar.scss */
  .navbar .nav-inner .nav-links .nav-item {
    width: 100%;
    height: 48px;
  }
}
/* line 95, ../sass/_navbar.scss */
.navbar .nav-inner .nav-links .nav-item .nav-link {
  padding: 0px 20px;
  height: 48px;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  color: #333333;
  position: relative;
  vertical-align: middle;
  line-height: 3.1;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 0.02em;
  transition: opacity 0.25s;
}
/* line 110, ../sass/_navbar.scss */
.navbar .nav-inner .nav-links .nav-item .nav-link:hover {
  opacity: 0.65;
}
/* line 114, ../sass/_navbar.scss */
.navbar .nav-inner .nav-links .nav-item .nav-logo {
  width: 22px;
  margin-top: 13px;
  transition: filter 0.25s;
}

/* line 1, ../sass/_home-text-hero.scss */
.home-text-hero-holder {
  height: calc(100vh - 48px - 50px);
  box-sizing: border-box;
  width: calc(100% - 50px);
  padding: 0px 25px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
/* line 11, ../sass/_home-text-hero.scss */
.home-text-hero-holder .home-text-hero-inner {
  display: block;
  height: auto;
  width: 1024px;
}
/* line 15, ../sass/_home-text-hero.scss */
.home-text-hero-holder .home-text-hero-inner .home-text {
  display: block;
  width: 100%;
  height: auto;
}
/* line 19, ../sass/_home-text-hero.scss */
.home-text-hero-holder .home-text-hero-inner .home-text .text {
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-size: 70px;
  line-height: 84px;
  display: inline-block;
  margin-right: 14px;
}
@media only screen and (max-width: 500px) {
  /* line 19, ../sass/_home-text-hero.scss */
  .home-text-hero-holder .home-text-hero-inner .home-text .text {
    font-size: 56px;
    line-height: 66px;
  }
}
@media only screen and (max-width: 321px) {
  /* line 19, ../sass/_home-text-hero.scss */
  .home-text-hero-holder .home-text-hero-inner .home-text .text {
    font-size: 48px;
    line-height: 58px;
  }
}
/* line 34, ../sass/_home-text-hero.scss */
.home-text-hero-holder .home-text-hero-inner .home-text .text-normal {
  font-weight: 500;
}
/* line 37, ../sass/_home-text-hero.scss */
.home-text-hero-holder .home-text-hero-inner .home-text .text-gradient {
  font-weight: 600;
  background-image: linear-gradient(to right, #4568dc, #b06ab3);
  color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: left top, right top;
  background-repeat: no-repeat;
}
/* line 46, ../sass/_home-text-hero.scss */
.home-text-hero-holder .home-text-hero-inner .home-text .slide-from-left {
  animation: slideFromLeft;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  transition: transform;
}
/* line 52, ../sass/_home-text-hero.scss */
.home-text-hero-holder .home-text-hero-inner .home-text .slide-from-top {
  animation: slideFromTop;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  transition: transform;
}
/* line 58, ../sass/_home-text-hero.scss */
.home-text-hero-holder .home-text-hero-inner .home-text .slide-from-bottom {
  animation: slideFromBottom;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  transition: transform;
}

@keyframes slideFromTop {
  0% {
    opacity: 0;
    transform: translate3d(0px, -500px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes slideFromBottom {
  0% {
    opacity: 0;
    transform: translate3d(0px, 500px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translate3d(-500px, 0px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes customFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes customFadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0px, 200px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}
/* line 19, ../sass/_hero.scss */
.hero {
  min-height: 900px;
  height: calc(100vh - 44px);
  box-sizing: border-box;
  animation: customFadeIn;
  animation-duration: 4.5s;
  overflow: hidden;
}
@media only screen and (max-width: 900px) {
  /* line 19, ../sass/_hero.scss */
  .hero {
    height: auto;
    overflow: hidden;
    min-height: 0px;
  }
}
/* line 31, ../sass/_hero.scss */
.hero .hero-media-holder {
  width: 100%;
  height: 576px;
  display: flex;
  justify-content: center;
  position: relative;
}
@media only screen and (max-width: 900px) {
  /* line 31, ../sass/_hero.scss */
  .hero .hero-media-holder {
    height: calc(55vh - 44px);
  }
}
/* line 40, ../sass/_hero.scss */
.hero .hero-media-holder .hero-media-img {
  max-width: 1064px;
  object-fit: contain;
  box-sizing: border-box;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  /* line 40, ../sass/_hero.scss */
  .hero .hero-media-holder .hero-media-img {
    width: 100%;
    padding: 10px;
  }
}
/* line 51, ../sass/_hero.scss */
.hero .hero-media-holder .hero-media-video {
  width: 1024px;
  height: 596px;
  outline: none;
  padding: 20px 0px;
  object-fit: cover;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  /* line 51, ../sass/_hero.scss */
  .hero .hero-media-holder .hero-media-video {
    width: 100%;
    height: 596px;
  }
}
@media only screen and (max-width: 900px) {
  /* line 51, ../sass/_hero.scss */
  .hero .hero-media-holder .hero-media-video {
    width: 100%;
    padding: 0px;
    height: 100%;
  }
}
/* line 68, ../sass/_hero.scss */
.hero .hero-media-holder .play-button-holder {
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  /* line 68, ../sass/_hero.scss */
  .hero .hero-media-holder .play-button-holder {
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
}
/* line 83, ../sass/_hero.scss */
.hero .hero-media-holder .play-button-holder .play-button {
  width: 100px;
  height: 100px;
}
@media only screen and (max-width: 900px) {
  /* line 83, ../sass/_hero.scss */
  .hero .hero-media-holder .play-button-holder .play-button {
    width: 80px;
    height: 80px;
  }
}
/* line 93, ../sass/_hero.scss */
.hero .title-holder {
  width: 100%;
  max-width: 980px;
  height: calc(100% - 576px);
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: customFadeUp;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}
@media only screen and (max-width: 900px) {
  /* line 93, ../sass/_hero.scss */
  .hero .title-holder {
    height: 35vh;
  }
}
@media only screen and (min-height: 820px) and (max-width: 600px) {
  /* line 93, ../sass/_hero.scss */
  .hero .title-holder {
    height: 20vh;
  }
}
/* line 111, ../sass/_hero.scss */
.hero .title-holder .hero-title {
  font-size: 80px;
  color: #1d1d1f;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.015em;
  line-height: 1.05;
  margin: 0px auto;
  max-width: 90%;
}
@media only screen and (max-width: 700px) {
  /* line 111, ../sass/_hero.scss */
  .hero .title-holder .hero-title {
    font-size: 54px;
    max-width: 95%;
    margin: 0px auto;
  }
}
@media only screen and (max-width: 500px) {
  /* line 111, ../sass/_hero.scss */
  .hero .title-holder .hero-title {
    font-size: 48px;
    max-width: 95%;
    margin: 0px auto;
  }
}

/* line 1, ../sass/_description.scss */
.description-holder {
  max-width: 880px;
  margin: 0px auto;
  height: auto;
  overflow: hidden;
}
@media only screen and (max-width: 900px) {
  /* line 1, ../sass/_description.scss */
  .description-holder {
    margin: 0px 25px;
  }
}
/* line 10, ../sass/_description.scss */
.description-holder .description-icon {
  width: 50px;
  height: 50px;
  margin-bottom: -15px;
}
/* line 15, ../sass/_description.scss */
.description-holder .description-title {
  color: #333333;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.015em;
  line-height: 1.05;
  margin: 25px auto 0px auto;
}
@media only screen and (max-width: 900px) {
  /* line 15, ../sass/_description.scss */
  .description-holder .description-title {
    font-size: 48px;
  }
}
/* line 27, ../sass/_description.scss */
.description-holder .title-small {
  font-size: 40px;
}
@media only screen and (max-width: 900px) {
  /* line 27, ../sass/_description.scss */
  .description-holder .title-small {
    font-size: 32px;
  }
}
/* line 33, ../sass/_description.scss */
.description-holder .title-large {
  font-size: 80px;
}
@media only screen and (max-width: 900px) {
  /* line 33, ../sass/_description.scss */
  .description-holder .title-large {
    font-size: 48px;
  }
}
/* line 39, ../sass/_description.scss */
.description-holder .description-p {
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 300;
  color: #333333;
  padding: 25px 0px;
}
/* line 44, ../sass/_description.scss */
.description-holder .description-p.left {
  margin: 0px auto 0px 0px;
}
/* line 47, ../sass/_description.scss */
.description-holder .description-p.center {
  margin: 0px auto 0px auto;
}
@media only screen and (max-width: 700px) {
  /* line 47, ../sass/_description.scss */
  .description-holder .description-p.center {
    margin: 0px 0px 0px 0px;
  }
}
/* line 53, ../sass/_description.scss */
.description-holder .description-p.right {
  margin: 0px 0px 0px auto;
}
/* line 57, ../sass/_description.scss */
.description-holder .p-small {
  font-size: 18px;
  line-height: 30px;
  max-width: 65%;
}
@media only screen and (max-width: 700px) {
  /* line 57, ../sass/_description.scss */
  .description-holder .p-small {
    max-width: 100%;
  }
}
/* line 65, ../sass/_description.scss */
.description-holder .p-large {
  font-size: 24px;
  line-height: 36px;
  max-width: 85%;
}
@media only screen and (max-width: 700px) {
  /* line 65, ../sass/_description.scss */
  .description-holder .p-large {
    font-size: 18px;
    max-width: 100%;
  }
}

/* line 1, ../sass/_phone-fade.scss */
.phone-fade-wrapper {
  margin: 0 auto;
  box-sizing: border-box;
}
/* line 4, ../sass/_phone-fade.scss */
.phone-fade-wrapper .not-sticky {
  width: 50%;
  min-height: 100vh;
  height: auto;
}
/* line 9, ../sass/_phone-fade.scss */
.phone-fade-wrapper .detail-holder {
  min-height: 500px;
  margin-top: 40vh;
  margin-bottom: 200px;
  padding: 20px;
  box-sizing: border-box;
}
@media only screen and (max-width: 900px) {
  /* line 9, ../sass/_phone-fade.scss */
  .phone-fade-wrapper .detail-holder {
    min-height: 0px;
    margin-top: 35px;
    margin-bottom: 60px;
    padding: 5px;
    width: calc(100% - 10px);
  }
}
/* line 23, ../sass/_phone-fade.scss */
.phone-fade-wrapper .detail-title {
  font-size: 24px;
  color: #333333;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 500;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 900px) {
  /* line 23, ../sass/_phone-fade.scss */
  .phone-fade-wrapper .detail-title {
    font-size: 22px;
    width: 100%;
    max-width: 100%;
  }
}
/* line 37, ../sass/_phone-fade.scss */
.phone-fade-wrapper .detail-subtitle {
  font-size: 48px;
  color: #1d1d1f;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 700;
  line-height: 1.05;
  margin-top: 35px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 900px) {
  /* line 37, ../sass/_phone-fade.scss */
  .phone-fade-wrapper .detail-subtitle {
    font-size: 34px;
    width: 100%;
    margin-top: 18px;
    max-width: 100%;
  }
}
/* line 54, ../sass/_phone-fade.scss */
.phone-fade-wrapper .detail-text {
  font-size: 24px;
  color: #333333;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 300;
  margin-top: 35px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  line-height: 36px;
}
@media only screen and (max-width: 900px) {
  /* line 54, ../sass/_phone-fade.scss */
  .phone-fade-wrapper .detail-text {
    font-size: 20px;
    margin-top: 18px;
    width: 100%;
    max-width: 100%;
  }
}
/* line 71, ../sass/_phone-fade.scss */
.phone-fade-wrapper .sticky {
  position: sticky;
  position: -webkit-sticky;
  width: 50%;
  height: calc(100vh - 40px);
  top: 20px;
  color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* line 83, ../sass/_phone-fade.scss */
.phone-fade-wrapper .iphoneX-holder {
  width: 340px;
  height: 713px;
  position: relative;
}
/* line 88, ../sass/_phone-fade.scss */
.phone-fade-wrapper .iphone8-holder {
  width: 340px;
  height: 589px;
  position: relative;
}
/* line 93, ../sass/_phone-fade.scss */
.phone-fade-wrapper .iphoneX-outline {
  width: 320px;
  height: 693px;
  border: 12px solid black;
  border-radius: 40px;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
@media only screen and (max-width: 900px) {
  /* line 93, ../sass/_phone-fade.scss */
  .phone-fade-wrapper .iphoneX-outline {
    width: 225px;
    height: 496px;
    border: 12px solid black;
    border-radius: 40px;
    z-index: 5;
    position: relative;
    overflow: hidden;
  }
}
/* line 113, ../sass/_phone-fade.scss */
.phone-fade-wrapper .iphone8-outline {
  width: 320px;
  height: 569px;
  border: 12px solid black;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
@media only screen and (max-width: 900px) {
  /* line 113, ../sass/_phone-fade.scss */
  .phone-fade-wrapper .iphone8-outline {
    width: 230px;
    height: 410px;
    border: 10px solid black;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}
/* line 133, ../sass/_phone-fade.scss */
.phone-fade-wrapper .iphone8-holder::after {
  background-color: black;
  height: calc(100% + 24px + 110px);
  width: calc(100% + 22px);
  content: "";
  position: absolute;
  bottom: -70px;
  left: -10px;
  border-radius: 40px;
}
/* line 143, ../sass/_phone-fade.scss */
.phone-fade-wrapper .phone-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: grey;
  z-index: 3;
  transition: opacity 0.5s;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
/* line 156, ../sass/_phone-fade.scss */
.phone-fade-wrapper .shown {
  opacity: 1;
  transition: opacity 0.5s;
}
/* line 160, ../sass/_phone-fade.scss */
.phone-fade-wrapper .hidden {
  opacity: 0;
  transition: opacity 0.5s;
}
/* line 164, ../sass/_phone-fade.scss */
.phone-fade-wrapper .sticky-section-wrapper {
  height: auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 1080px;
  margin: 0px auto;
}
/* line 173, ../sass/_phone-fade.scss */
.phone-fade-wrapper .mobile-sticky-section-wrapper {
  width: 100%;
}
/* line 176, ../sass/_phone-fade.scss */
.phone-fade-wrapper .mobile-iphone8-holder {
  width: 250px;
  height: 445px;
  position: relative;
  margin-left: calc(50% - 125px - 4px);
  margin-bottom: 80px;
}
/* line 182, ../sass/_phone-fade.scss */
.phone-fade-wrapper .mobile-iphone8-holder::after {
  background-color: black;
  height: calc(100% + 20px + 50px);
  width: calc(100% + 10px);
  content: "";
  position: absolute;
  bottom: -26px;
  left: -5px;
  border-radius: 40px;
}
/* line 193, ../sass/_phone-fade.scss */
.phone-fade-wrapper .mobile-iphoneX-holder {
  width: 250px;
  height: 520px;
  margin-left: calc(50% - 125px - 2px);
  margin-top: -30px;
}

/* line 1, ../sass/_full-card.scss */
.card-full {
  max-width: calc(980px - 100px);
  width: calc(100% - 100px - 50px);
  height: auto;
  margin: 50px auto 25px auto;
  background: #ffffff;
  box-shadow: 4px 4px 16px #cdcdcd;
  border-radius: 6px;
  padding: 80px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  transition: transform 0.25s;
  transform: translate3d(0px, 125px, 0px);
}
@media only screen and (max-width: 700px) {
  /* line 1, ../sass/_full-card.scss */
  .card-full {
    width: calc(100% - 100px);
    margin: 25px auto;
    padding: 40px 25px;
  }
}
/* line 22, ../sass/_full-card.scss */
.card-full .card-left {
  display: block;
  width: calc(45% - 50px);
}
@media only screen and (max-width: 980px) {
  /* line 22, ../sass/_full-card.scss */
  .card-full .card-left {
    width: 100%;
  }
}
/* line 28, ../sass/_full-card.scss */
.card-full .card-left .icon-holder {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 25px;
}
@media only screen and (max-width: 700px) {
  /* line 28, ../sass/_full-card.scss */
  .card-full .card-left .icon-holder {
    margin-bottom: 15px;
  }
}
/* line 36, ../sass/_full-card.scss */
.card-full .card-left .icon-holder .card-icon {
  width: 50px;
  margin-right: 10px;
}
@media only screen and (max-width: 700px) {
  /* line 36, ../sass/_full-card.scss */
  .card-full .card-left .icon-holder .card-icon {
    width: 40px;
  }
}
/* line 43, ../sass/_full-card.scss */
.card-full .card-left .icon-holder .icon-title {
  font-size: 22px;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  color: #333333;
  font-weight: 500;
}
@media only screen and (max-width: 700px) {
  /* line 43, ../sass/_full-card.scss */
  .card-full .card-left .icon-holder .icon-title {
    font-size: 18px;
  }
}
/* line 53, ../sass/_full-card.scss */
.card-full .card-left .card-title {
  font-size: 36px;
  color: #333333;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
}
@media only screen and (max-width: 700px) {
  /* line 53, ../sass/_full-card.scss */
  .card-full .card-left .card-title {
    font-size: 28px;
  }
}
/* line 63, ../sass/_full-card.scss */
.card-full .card-left .card-p {
  font-size: 18px;
  line-height: 26px;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 400;
}
/* line 70, ../sass/_full-card.scss */
.card-full .card-right {
  display: block;
  text-align: center;
  height: auto;
  width: 55%;
}
@media only screen and (max-width: 980px) {
  /* line 70, ../sass/_full-card.scss */
  .card-full .card-right {
    width: 100%;
    margin-top: 25px;
  }
}
/* line 79, ../sass/_full-card.scss */
.card-full .card-right .card-image {
  width: 100%;
  box-shadow: 4px 4px 16px #cdcdcd;
  border-radius: 6px;
}

/* line 1, ../sass/_card-dual.scss */
.card-dual {
  max-width: 980px;
  width: calc(100% - 50px);
  height: auto;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  transition: transform 0.25s;
  transform: translate3d(0px, 125px, 0px);
}
@media only screen and (max-width: 980px) {
  /* line 1, ../sass/_card-dual.scss */
  .card-dual {
    margin: 25px auto;
  }
}
/* line 15, ../sass/_card-dual.scss */
.card-dual .dual-card {
  width: calc(50% - 100px - 25px);
  float: left;
  background: #ffffff;
  box-shadow: 4px 4px 16px #cdcdcd;
  border-radius: 6px;
  padding: 80px 50px;
}
@media only screen and (max-width: 980px) {
  /* line 15, ../sass/_card-dual.scss */
  .card-dual .dual-card {
    width: calc(100% - 50px);
    margin: 25px 0px;
    padding: 40px 25px;
  }
}
/* line 27, ../sass/_card-dual.scss */
.card-dual .dual-card .card-left {
  display: block;
  width: 100%;
}
/* line 30, ../sass/_card-dual.scss */
.card-dual .dual-card .card-left .icon-holder {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 25px;
}
@media only screen and (max-width: 700px) {
  /* line 30, ../sass/_card-dual.scss */
  .card-dual .dual-card .card-left .icon-holder {
    margin-bottom: 15px;
  }
}
/* line 38, ../sass/_card-dual.scss */
.card-dual .dual-card .card-left .icon-holder .card-icon {
  width: 50px;
  margin-right: 10px;
}
@media only screen and (max-width: 700px) {
  /* line 38, ../sass/_card-dual.scss */
  .card-dual .dual-card .card-left .icon-holder .card-icon {
    width: 40px;
  }
}
/* line 45, ../sass/_card-dual.scss */
.card-dual .dual-card .card-left .icon-holder .icon-title {
  font-size: 22px;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  color: #333333;
  font-weight: 500;
}
@media only screen and (max-width: 700px) {
  /* line 45, ../sass/_card-dual.scss */
  .card-dual .dual-card .card-left .icon-holder .icon-title {
    font-size: 18px;
  }
}
/* line 55, ../sass/_card-dual.scss */
.card-dual .dual-card .card-left .card-title {
  font-size: 36px;
  color: #333333;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
}
@media only screen and (max-width: 700px) {
  /* line 55, ../sass/_card-dual.scss */
  .card-dual .dual-card .card-left .card-title {
    font-size: 28px;
  }
}
/* line 65, ../sass/_card-dual.scss */
.card-dual .dual-card .card-left .card-p {
  font-size: 18px;
  line-height: 26px;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 400;
}
/* line 72, ../sass/_card-dual.scss */
.card-dual .dual-card .card-right {
  display: block;
  text-align: center;
  height: auto;
  width: 100%;
  margin-top: 25px;
}
/* line 78, ../sass/_card-dual.scss */
.card-dual .dual-card .card-right .card-image {
  width: 100%;
  box-shadow: 4px 4px 16px #cdcdcd;
  border-radius: 6px;
}

/* line 1, ../sass/_small-info.scss */
.small-info-holder {
  width: 100%;
  text-align: center;
  padding: 25px 0px;
}
/* line 5, ../sass/_small-info.scss */
.small-info-holder .small-info {
  padding: 12px 24px;
  border: 1px solid #333333;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  width: auto;
  border-radius: 50px;
  color: #333333;
  white-space: nowrap;
}

/* line 1, ../sass/_safari-view.scss */
.safari-holder {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0px;
}
/* line 10, ../sass/_safari-view.scss */
.safari-holder .safari-main {
  display: block;
  position: relative;
  width: 980px;
  height: 551px;
  box-shadow: 4px 4px 16px #cdcdcd;
  border-radius: 6px;
  overflow: hidden;
  transition: transform 0.5s;
  transform: translate3d(0px, 125px, 0px);
}
@media only screen and (max-width: 980px) {
  /* line 10, ../sass/_safari-view.scss */
  .safari-holder .safari-main {
    width: calc(100% - 50px);
    margin: 0px 25px;
    height: 0px;
    padding-top: 56.25%;
  }
}
/* line 26, ../sass/_safari-view.scss */
.safari-holder .safari-main .top-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4%;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* line 36, ../sass/_safari-view.scss */
.safari-holder .safari-main .top-bar .dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-left: 7px;
}
@media only screen and (max-width: 900px) {
  /* line 36, ../sass/_safari-view.scss */
  .safari-holder .safari-main .top-bar .dot {
    width: 3px;
    height: 3px;
    margin-left: 4px;
  }
}
/* line 46, ../sass/_safari-view.scss */
.safari-holder .safari-main .top-bar .dot.red {
  background-color: #e74c3c;
  border: 1px solid #cf4436;
}
/* line 50, ../sass/_safari-view.scss */
.safari-holder .safari-main .top-bar .dot.yellow {
  background-color: #f1c40f;
  border: 1px solid #d8b00d;
}
/* line 54, ../sass/_safari-view.scss */
.safari-holder .safari-main .top-bar .dot.green {
  background-color: #27ae60;
  border: 1px solid #239c56;
}
/* line 60, ../sass/_safari-view.scss */
.safari-holder .safari-main .main-view {
  position: absolute;
  left: 0;
  top: 4%;
  width: 100%;
  height: 96%;
  background-size: cover;
}
/* line 69, ../sass/_safari-view.scss */
.safari-holder .safari-main.safari-animated {
  transform: translate3d(0px, 0px, 0px);
}

/* line 1, ../sass/_full-image.scss */
.image-holder {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0px;
}
/* line 10, ../sass/_full-image.scss */
.image-holder .image-main {
  display: block;
  position: relative;
  width: 980px;
  height: 551px;
  box-shadow: 4px 4px 16px #cdcdcd;
  border-radius: 6px;
  overflow: hidden;
  transition: transform 0.5s;
  transform: translate3d(0px, 125px, 0px);
}
@media only screen and (max-width: 980px) {
  /* line 10, ../sass/_full-image.scss */
  .image-holder .image-main {
    width: calc(100% - 50px);
    margin: 0px 25px;
    height: 0px;
    padding-top: calc(56.25% - 25px);
  }
}
/* line 26, ../sass/_full-image.scss */
.image-holder .image-main .main-view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
/* line 35, ../sass/_full-image.scss */
.image-holder .image-main.image-animated {
  transform: translate3d(0px, 0px, 0px);
}

/* line 1, ../sass/_half-image.scss */
.half-image-holder {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0px;
}
/* line 10, ../sass/_half-image.scss */
.half-image-holder .half-image-main {
  max-width: 1024px;
  display: block;
  height: auto;
  overflow: hidden;
}
/* line 15, ../sass/_half-image.scss */
.half-image-holder .half-image-main .half-image-left {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 15px;
}
@media only screen and (max-width: 900px) {
  /* line 15, ../sass/_half-image.scss */
  .half-image-holder .half-image-main .half-image-left {
    width: 100%;
    padding: 15px 25px;
  }
}
/* line 24, ../sass/_half-image.scss */
.half-image-holder .half-image-main .half-image-left .half-title {
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-size: 40px;
  font-weight: 600;
}
/* line 29, ../sass/_half-image.scss */
.half-image-holder .half-image-main .half-image-left .half-description {
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-size: 24px;
  font-weight: 300;
  margin-top: 20px;
}
/* line 36, ../sass/_half-image.scss */
.half-image-holder .half-image-main .half-image-right {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 15px;
}
@media only screen and (max-width: 900px) {
  /* line 36, ../sass/_half-image.scss */
  .half-image-holder .half-image-main .half-image-right {
    width: 100%;
    padding: 15px 25px;
    text-align: center;
  }
}
/* line 46, ../sass/_half-image.scss */
.half-image-holder .half-image-main .half-image-right .image {
  width: 100%;
}
@media only screen and (max-width: 900px) {
  /* line 46, ../sass/_half-image.scss */
  .half-image-holder .half-image-main .half-image-right .image {
    width: 80%;
  }
}

/* line 1, ../sass/_tall-image.scss */
.tall-image-holder {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}
@media only screen and (max-width: 900px) {
  /* line 1, ../sass/_tall-image.scss */
  .tall-image-holder {
    padding: 0px;
  }
}
/* line 13, ../sass/_tall-image.scss */
.tall-image-holder .tall-image-main {
  width: calc(100% - 50px);
  max-width: 980px;
  display: block;
  height: auto;
  overflow: hidden;
  box-shadow: 4px 4px 16px #cdcdcd;
  border-radius: 6px;
  transition: transform 0.5s;
  transform: translate3d(0px, 125px, 0px);
}
/* line 23, ../sass/_tall-image.scss */
.tall-image-holder .tall-image-main.image-animated {
  transform: translate3d(0px, 0px, 0px);
}
/* line 26, ../sass/_tall-image.scss */
.tall-image-holder .tall-image-main .tall-image {
  width: 100%;
}

/* line 1, ../sass/_youtube.scss */
.youtube-holder {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0px;
}
/* line 10, ../sass/_youtube.scss */
.youtube-holder .youtube-main {
  display: block;
  position: relative;
  width: 980px;
  height: 551px;
  box-shadow: 4px 4px 16px #cdcdcd;
  border-radius: 6px;
  overflow: hidden;
  transition: transform 0.5s;
  transform: translate3d(0px, 125px, 0px);
}
@media only screen and (max-width: 980px) {
  /* line 10, ../sass/_youtube.scss */
  .youtube-holder .youtube-main {
    width: calc(100% - 50px);
    margin: 0px 25px;
    height: 0px;
    padding-top: calc(56.25% - 25px);
  }
}
/* line 26, ../sass/_youtube.scss */
.youtube-holder .youtube-main .video-player {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/* line 33, ../sass/_youtube.scss */
.youtube-holder .youtube-main.video-animated {
  transform: translate3d(0px, 0px, 0px);
}

/* line 1, ../sass/_large-quote.scss */
.large-quote-holder {
  width: calc(100% - 50px);
  padding: 0px 25px;
  height: auto;
}
/* line 5, ../sass/_large-quote.scss */
.large-quote-holder .large-quote-inner {
  max-width: 900px;
  margin: 0px auto;
  padding: 50px 0px;
}
/* line 9, ../sass/_large-quote.scss */
.large-quote-holder .large-quote-inner .quote-mark-begin {
  font-family: "Shadows Into Light";
  font-size: 80px;
  font-weight: 500;
  text-align: left;
  line-height: 0px;
  margin-bottom: 0px;
}
/* line 17, ../sass/_large-quote.scss */
.large-quote-holder .large-quote-inner .quote-mark-end {
  font-family: "Shadows Into Light";
  font-size: 80px;
  font-weight: 500;
  text-align: right;
  line-height: 0px;
  margin-top: 25px;
}
/* line 25, ../sass/_large-quote.scss */
.large-quote-holder .large-quote-inner .quote {
  font-size: 36px;
  font-family: "Shadows Into Light", cursive;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0px;
  letter-spacing: 0.03em;
}
/* line 33, ../sass/_large-quote.scss */
.large-quote-holder .large-quote-inner .byline {
  text-align: right;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
  font-weight: 300;
  font-size: 24px;
  margin-top: 25px;
}

/* line 1, ../sass/_footer.scss */
.footer-holder {
  max-width: 880px;
  margin: 0px auto;
  height: auto;
  overflow: hidden;
  padding: 75px 0px 35px 0px;
}
@media only screen and (max-width: 980px) {
  /* line 1, ../sass/_footer.scss */
  .footer-holder {
    width: 100%;
    box-sizing: border-box;
    padding: 75px 20px 35px 20px;
  }
}
/* line 12, ../sass/_footer.scss */
.footer-holder .footer-title, .footer-holder .footer-subtitle, .footer-holder .link-collection-title, .footer-holder .link a, .footer-holder .footer-legal {
  color: #333;
  font-family: system-ui, -apple-system, "Helvetica Neue", Helvetica, "Lato", sans-serif;
}
/* line 16, ../sass/_footer.scss */
.footer-holder .footer-top {
  height: auto;
  overflow: hidden;
  margin-bottom: 35px;
  box-shadow: 0px -1px 0px 0px #c6c6c6;
  padding-top: 25px;
}
/* line 22, ../sass/_footer.scss */
.footer-holder .footer-top .profile-image {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 1px solid #fff;
  float: left;
  margin-right: 25px;
}
/* line 31, ../sass/_footer.scss */
.footer-holder .footer-top .footer-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
}
/* line 36, ../sass/_footer.scss */
.footer-holder .footer-top .footer-subtitle {
  font-weight: 400;
  margin-bottom: 4px;
  font-size: 14px;
}
/* line 42, ../sass/_footer.scss */
.footer-holder .links-holder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  flex-wrap: wrap;
  flex-direction: row;
}
@media only screen and (max-width: 700px) {
  /* line 42, ../sass/_footer.scss */
  .footer-holder .links-holder {
    margin-bottom: 0px;
  }
}
/* line 51, ../sass/_footer.scss */
.footer-holder .links-holder .link-collection {
  width: calc(50% - 25px);
  box-sizing: border-box;
}
@media only screen and (max-width: 700px) {
  /* line 51, ../sass/_footer.scss */
  .footer-holder .links-holder .link-collection {
    width: 100%;
    margin-bottom: 35px;
  }
}
/* line 58, ../sass/_footer.scss */
.footer-holder .links-holder .link-collection .link-collection-title {
  border-bottom: 1px solid #c6c6c6;
  padding: 0px 0px 8px 0px;
  font-size: 16px;
  font-weight: 500;
}
/* line 64, ../sass/_footer.scss */
.footer-holder .links-holder .link-collection .link-list {
  list-style: none;
  padding: 0;
  margin: 15px 0px;
}
/* line 69, ../sass/_footer.scss */
.footer-holder .links-holder .link-collection .link {
  font-size: 14px;
  margin: 12px 0px;
}
/* line 72, ../sass/_footer.scss */
.footer-holder .links-holder .link-collection .link a {
  padding-bottom: 3px;
  text-decoration: none;
}
/* line 75, ../sass/_footer.scss */
.footer-holder .links-holder .link-collection .link a:hover {
  border-bottom: 1px solid #333;
}
/* line 82, ../sass/_footer.scss */
.footer-holder .footer-bottom {
  box-shadow: 0px -1px 0px 0px #c6c6c6;
  padding-top: 10px;
}
/* line 85, ../sass/_footer.scss */
.footer-holder .footer-bottom .footer-legal {
  font-size: 12px;
  color: #636363;
  display: inline-block;
  margin-right: 10px;
}
/* line 90, ../sass/_footer.scss */
.footer-holder .footer-bottom .footer-legal a {
  color: #636363;
  padding-bottom: 3px;
  text-decoration: none;
}
/* line 94, ../sass/_footer.scss */
.footer-holder .footer-bottom .footer-legal a:hover {
  border-bottom: 1px solid #333;
}

/* line 1, ../sass/_scroll-indicator.scss */
.scroll-indicator {
  position: absolute;
  width: 25px;
  left: calc(50% - 12.5px);
}
/* line 5, ../sass/_scroll-indicator.scss */
.scroll-indicator .down-arrow {
  width: 25px;
}
/* line 8, ../sass/_scroll-indicator.scss */
.scroll-indicator .arrow-top, .scroll-indicator .arrow-bottom {
  opacity: 0;
}
/* line 11, ../sass/_scroll-indicator.scss */
.scroll-indicator .arrow-top.animate, .scroll-indicator .arrow-bottom.animate {
  animation: pulse;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
/* line 17, ../sass/_scroll-indicator.scss */
.scroll-indicator .arrow-bottom.animate {
  animation-delay: 0.35s;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* line 28, ../sass/style.scss */
.card-animated {
  transition: transform 0.5s;
  transform: translate3d(0px, 0px, 0px);
}

/* line 32, ../sass/style.scss */
* {
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: none;
}

/* line 43, ../sass/style.scss */
html, body, h1, h2, h3, h4, h5, h6, p, a {
  margin: 0;
  padding: 0;
}
